
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { Box, ErrorState, AppLogo, Button } from '@hawkins/components';
import { spacings } from '@hawkins/variables';

import { TranslationNamespace } from 'types';

export default function Custom404() {
  const router = useRouter();
  const { t } = useTranslation(TranslationNamespace.LEP);

  return (
    <>
      <Head>
        <title>Netflix Law Enforcement Portal</title>
        <link href="/images/favicon.jpg" rel="icon" />
      </Head>
      <Box display="flex" justifyContent="center" paddingX={spacings.space3}>
        <ErrorState
          actions={
            <Button onClick={() => router.push('/')} variant="primary">
              {t('return_home')}
            </Button>
          }
          appLogo={<AppLogo name={t('legal_requests')} studioLogo={true} />}
          title={t('404_error_msg')}
        />
      </Box>
    </>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  